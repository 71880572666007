import styles from './Contact.module.css'
import LinkButton from '../layout/LinkButton'

function Contact() {
    return (
    <section className={styles.contact_container}>
        <h1>
            <p>Como podemos auxiliar tua industria?</p>
        </h1>
    <p>Entre em contato conosco agora mesmo!</p>
    <LinkButton to="https://wa.me/5547997528087" text="Converse conosco"/>
  </section>
)
}

export default Contact