import { Facebook, FaFacebook, FaInstagram, FaLinkedin} from 'react-icons/fa'
import styles from './Footer.module.css'

function Footer() {
    return (
    <footer className={styles.footer}> 
        <ul className={styles.social_list}>
            <li><FaFacebook /></li>
            <li><FaInstagram /></li>
            <li><FaLinkedin /></li>
        </ul>
        <p>(47) 99752-8087</p>
        <p>Rio Negro - Paraná</p>
        <p className={styles.copy_right}><span>Akros Engenharia Ltda.</span> &copy; 2024</p>

    </footer>)    
}    
export default Footer