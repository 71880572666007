import LinkButton from '../layout/LinkButton'
import styles from './Home.module.css'
import { Swiper, SwiperSlide} from 'swiper/react'
import { register } from 'swiper/element/bundle';
import loja from "../../img/loja.png";
import balanceamento from "../../img/balanceamento.png";
import silo from "../../img/silo.png";
import projetos from "../../img/projetos.png";
import Container from '../layout/Container';


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';

register();


function Home() {

    const data = [
        { id: '1', image: loja},
        { id: '2', image: balanceamento},
        { id: '3', image: projetos},
        { id: '4', image: silo},
        
]

    return  (
        
        <section>
            <div className={styles.home_container}>
            
            <Container>
            <Swiper className={styles.slide} 
             slidesPerView={1}
             pagination={{clickable: true}}
             navigation={{clickable: true}}
            
            >
             {data.map( (item) => (
                <SwiperSlide key={item.id}>
                    <img
                        src={item.image}
                        alt="Slider"
                        className={styles.slide_item}                   
                    />
                </SwiperSlide>
             ))}
            </Swiper>
            </Container>
            </div>
            <div className={styles.wrapper}> 
                <Card
                title="Produtos e Acessórios"
                description="Abrasivos, consumíveis para soldagem, ferramentas elétricas, ferramentas manuais, parafusos e fixadores, metais e rolamentos"                 
                />
                <Card
                title="Balanceamento"
                description="Balanceamento e análise de vibração em: 
                ventiladores, exaustores, eixos, polias, induzidos de motores elétricos, hélices e picadores"                  
                />
                <Card
                title="Fabricação"
                description="Bifurcadas revestidas, trifurcadas revestidas, amortecedores, elevadores de canecas roscas helicoidais, coifas e exaustores"                 
                />
                <Card
                title="Manutenção Industrial"
                description="Manutenção preditivas, manutenção preventiva e manutenção emergencial com equipe de plantão"                 
                />
                <Card
                title="Manutenções em Silos"
                description="Canalização, fitas transportadoras, moegas e redlers"                 
                />
                <Card
                title="Projetos"
                description="Desenhos em 3D, cadastro de peças, corte em plasma, soldagem e instalação"                 
                />
            </div>  
            
        </section>
        
    )

    function Card(props) {
        return (
        <div className={styles.card}>
        <div className={styles.body}>
            <h2 className={styles.card__title}>{props.title}</h2>
            <p className={styles.card__description}>{props.description}</p>
        </div>
        <button className={styles.card__btn}><Link to="/contact">Conheça</Link></button>
        </div>
    );
}
    
}

export default  Home

