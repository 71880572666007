import { Link } from "react-router-dom";
import Container from "./Container";
import { useState } from "react";

import styles from "./Navbar.module.css";
import logo from "../../img/logo.png";

function Navbar(){

    const [active, setActive] = useState(`${styles.nav_menu}`);

    const navToggle = () => {
        /*aqui o styles é um objeto, entre apóstrofo é variável, $ é interpolação da string*/
        if (active === (`${styles.nav_menu}`)) {
          setActive(` ${styles.nav_active}`);
        } else setActive((`${styles.nav_menu}`));    
    };
        
    return (
        
        <div className={styles.navbar}>
            <Container>
            <div className={styles.logo}>
                <Link to="/">
                    <img src={logo} alt="logo" height={40} />
                </Link>            
            </div>
            <ul className={active}>
                <li className={styles.item}>                
                    <Link to="/">Home</Link>
                </li>
                <li className={styles.item}>
                    <Link to="/contact">Loja</Link>            
                </li>
                <li className={styles.item}>
                    <Link to="/contact">Serviços</Link>            
                </li>
                <li className={styles.item}>
                    <Link to="/contact">Projetos</Link>
                </li>
            </ul>
            
            <div className={styles.nav_toggler} onClick={navToggle}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </Container>   
        </div>   
    )
    
}

/*fazer a logo se ajustar ao tamanho, botão de contato , menu fechar depois um tempo*/

export default Navbar